import { generateRgbVars } from '@/helpers/generate-rgb-color-variables'
import { localStorageKey } from '@/helpers/main-config'
import { useLocalStorage, usePreferredColorScheme } from '@vueuse/core'
import { last } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export enum Theme {
  Dark = 'Dark',
  Light = 'Light',
  Auto = 'Auto',
}

export type ToastData = {
  text: string
  emoji: string
}

export type LoaderData = {
  id: string
  text: string
  warnBeforeClose: boolean
}

export const useAppStore = defineStore('app', () => {
  const route = useRoute()

  // THEME

  const theme = useLocalStorage(localStorageKey.theme, Theme.Dark)

  const preferredColor = usePreferredColorScheme()

  const isDarkTheme = computed(() => {
    return theme.value === Theme.Dark || (theme.value === Theme.Auto && preferredColor.value === 'dark')
  })

  watch(
    isDarkTheme,
    (to) => {
      if (to) {
        document.body.classList.add('dark')
      } else {
        document.body.classList.remove('dark')
      }
      generateRgbVars()
    },
    { immediate: true },
  )

  // TOAST

  const toastData = ref<null | ToastData>(null)
  const cursorToastData = ref<null | ToastData>(null)

  const dismissToast = (type: 'top' | 'cursor') => {
    const data = type === 'top' ? toastData : cursorToastData
    data.value = null
  }

  const showToast = async (type: 'top' | 'cursor', payload: ToastData) => {
    const data = type === 'top' ? toastData : cursorToastData

    if (data.value !== null) {
      data.value = null
      await nextTick()
    }

    data.value = payload
    setTimeout(() => {
      dismissToast(type)
    }, 3000)
  }

  // LOADER

  const loaders = ref<LoaderData[]>([])
  const showLoader = (id: string, text: string, warnBeforeClose = false) => {
    loaders.value.push({ id, text, warnBeforeClose })
  }
  const hideLoader = (id: string) => {
    loaders.value = loaders.value.filter((l) => l.id !== id)
  }
  const loaderData = computed(() => last(loaders.value))

  // FEEDBACK

  const isFeedbackShown = ref<boolean>(false)
  const showFeedbackModal = () => {
    isFeedbackShown.value = true
  }
  const hideFeedbackModal = () => {
    isFeedbackShown.value = false
  }
  const showSeparateFeedbackBtn = computed(() => {
    return route.name !== 'typing' && route.name !== 'intro' && route.name !== 'result'
  })

  // PAYMENT SELECTION MODAL (RU)
  const isPaymentSelectionShown = ref<boolean>(false)

  // XMAS MODE
  const xmasMode = ref(false)

  return {
    theme,
    isDarkTheme,
    showToast,
    dismissToast,
    toastData,
    cursorToastData,
    loaderData,
    showLoader,
    hideLoader,
    isFeedbackShown,
    showFeedbackModal,
    hideFeedbackModal,
    showSeparateFeedbackBtn,
    isPaymentSelectionShown,
    xmasMode,
  }
})
