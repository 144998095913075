import { PunctuationMarkType, type LanguageMetadata } from '../lang-types'
import words10k from './cs_10k.json'
import ngrams from './cs_ngrams.json'

// prettier-ignore
// (printWidth)
export const csMetadata: LanguageMetadata = {
  code: 'cs',
  title: 'Czech',
  emoji: '🇨🇿',

  lowerLetters: 'aábcčdďeéěfghiíjklmnňoópqrřsštťuúůvwxyýzž',
  upperLetters: 'AÁBCČDĎEÉĚFGHIÍJKLMNŇOÓPQRŘSŠTŤUÚŮVWXYÝZŽ',
  nonEssentialLetters: '',

  punctuation: [
    {
      char: '-',
      type: PunctuationMarkType.Hyphen,
    },
    // {
    //   char: '—',
    //   type: PunctuationMarkType.Dash,
    // },
    {
      char: ',',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '.',
      type: PunctuationMarkType.End,
    },
    {
      char: '?',
      type: PunctuationMarkType.End,
    },
    {
      char: '!',
      type: PunctuationMarkType.End,
    },
    {
      char: ':',
      type: PunctuationMarkType.Comma,
    },
    {
      char: ';',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '()',
      type: PunctuationMarkType.Bracket,
    },
    {
      char: '"',
      type: PunctuationMarkType.Bracket,
    },
    // {
    //   char: '‚‘',
    //   type: PunctuationMarkType.Bracket,
    // },
    // {
    //   char: '„“',
    //   type: PunctuationMarkType.Bracket,
    // },
  ],
  
  dicts: {
    words10k,
    ngrams,
  }
}
