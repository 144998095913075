import { PunctuationMarkType, type LanguageMetadata } from '../lang-types'
import words10k from './nl_10k.json'
import ngrams from './nl_ngrams.json'

// prettier-ignore
// (printWidth)
export const nlMetadata: LanguageMetadata = {
  code: 'nl',
  title: 'Dutch',
  emoji: '🇳🇱',

  lowerLetters: 'aäbcdeéëfghiïjklmnoöpqrstuüvwxyÿz',
  upperLetters: 'AÄBCDEÉËFGHIÏJKLMNOÖPQRSTUÜVWXYŸZ',
  nonEssentialLetters: 'Ÿ',

  punctuation: [
    {
      char: '-',
      type: PunctuationMarkType.Hyphen,
    },
    // {
    //   char: '—',
    //   type: PunctuationMarkType.Dash,
    // },
    {
      char: ',',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '.',
      type: PunctuationMarkType.End,
    },
    {
      char: '?',
      type: PunctuationMarkType.End,
    },
    {
      char: '!',
      type: PunctuationMarkType.End,
    },
    {
      char: ':',
      type: PunctuationMarkType.Comma,
    },
    {
      char: ';',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '()',
      type: PunctuationMarkType.Bracket,
    },
    {
      char: '\'',
      type: PunctuationMarkType.Bracket,
    },
    {
      char: '"',
      type: PunctuationMarkType.Bracket,
    },
  ],
  
  dicts: {
    words10k,
    ngrams,
  }
}
