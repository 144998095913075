import type { LanguageCode } from '@/languages/lang-types'
import type { KeyboardLayout } from './keyboards/KeyboardLayout'
import { allLangConfig } from '@/languages/all-lang-config'
import { Char } from './keyboards/KeyChar'

export const get10Dict = (lang: LanguageCode, layout: KeyboardLayout) => {
  const langConfig = allLangConfig[lang]
  const dict = langConfig.dicts.words10k
  const nonEssentialLetters = langConfig.nonEssentialLetters

  const unsupportedLetters = nonEssentialLetters.split('').filter((letter) => !layout.getKeysToType(new Char(letter)))
  if (unsupportedLetters.length) {
    return dict.filter((word) => unsupportedLetters.findIndex((unsupportedLetter) => word.includes(unsupportedLetter)) === -1)
  }
  return dict
}

export const getNgramsDict = (lang: LanguageCode, ngramLen: 2 | 3 | 4, layout: KeyboardLayout) => {
  const langConfig = allLangConfig[lang]
  const dict = langConfig.dicts.ngrams[ngramLen.toString() as '2' | '3' | '4']
  const nonEssentialLetters = langConfig.nonEssentialLetters

  const unsupportedLetters = nonEssentialLetters.split('').filter((letter) => !layout.getKeysToType(new Char(letter)))
  if (unsupportedLetters.length) {
    return dict.filter((word) => unsupportedLetters.findIndex((unsupportedLetter) => word.includes(unsupportedLetter)) === -1)
  }
  return dict
}
