import { csMetadata } from './cs/cs-lang-config'
import { daMetadata } from './da/da-lang-config'
import { deMetadata } from './de/de-lang-config'
import { enMetadata } from './en/en-lang-config'
import { esMetadata } from './es/es-lang-config'
import { frMetadata } from './fr/fr-lang-config'
import { huMetadata } from './hu/hu-lang-config'
import { itMetadata } from './it/it-lang-config'
import type { LanguageCode, LanguageMetadata } from './lang-types'
import { nlMetadata } from './nl/nl-lang-config'
import { plMetadata } from './pl/pl-lang-config'
import { ptMetadata } from './pt/pt-lang-config'
import { roMetadata } from './ro/ro-lang-config'
import { ruMetadata } from './ru/ru-lang-config'
import { svMetadata } from './sv/sv-lang-config'
import { trMetadata } from './tr/tr-lang-config'
import { ukMetadata } from './uk/uk-lang-config'

export const allLangConfig: Record<LanguageCode, LanguageMetadata> = {
  en: enMetadata,
  es: esMetadata,
  de: deMetadata,
  ru: ruMetadata,
  fr: frMetadata,
  pt: ptMetadata,
  it: itMetadata,
  pl: plMetadata,
  uk: ukMetadata,
  tr: trMetadata,
  nl: nlMetadata,
  sv: svMetadata,
  hu: huMetadata,
  cs: csMetadata,
  da: daMetadata,
  ro: roMetadata,
}
