import { Finger, Hand, Row } from '@/helpers/keyboards/FingerMapping'
import { KeyboardFormat, type KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { Char } from '@/helpers/keyboards/KeyChar'
import type { TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { Layer } from '@/helpers/keyboards/Layer'
import { allLangConfig } from '@/languages/all-lang-config'
import { last } from 'lodash-es'
import { Chapter, type Course, type LessonData, LessonType, TrainerView } from './course-types'
import { numTrainingItems } from './numbers/course-numbers-config'
import { getNgramsDict } from '@/helpers/lang-helper'

export const LOWER_WORDS_LESSONS_COUNT = 5

export const buildCourse = (layout: KeyboardLayout): Course | null => {
  if (layout.format === KeyboardFormat.Unknown) {
    return null
  }

  let chapters = {} as Record<Chapter, LessonData[]>

  // prettier-ignore
  const keyPairsByRow: TypeableKeyCode[][][] = [
    [['KeyF', 'KeyJ'], ['KeyD', 'KeyK'], ['KeyS', 'KeyL'], ['KeyA', 'Semicolon'], ['KeyG', 'KeyH']],
    [['KeyR', 'KeyU'], ['KeyE', 'KeyI'], ['KeyW', 'KeyO'], ['KeyQ', 'KeyP'], ['KeyT', 'KeyY']],
    [['KeyV', 'KeyM'], ['KeyC', 'Comma'], ['KeyX', 'Period'], ['KeyZ', 'Slash'], ['KeyB', 'KeyN']],
  ]
  const rowChapters = [Chapter.HomeRow, Chapter.TopRow, Chapter.BottomRow]

  for (let i = 0; i < rowChapters.length; i++) {
    const row = keyPairsByRow[i]
    const chapterKey = rowChapters[i]

    const chapter: LessonData[] = []
    for (const pair of row) {
      chapter.push({
        type: LessonType.NewLetters,
        uniqueId: `${chapterKey}-${LessonType.NewLetters}-${pair.join('')}`,
        view: TrainerView.RunningLine,
        availableOnTrial: chapterKey === Chapter.HomeRow,
        metadata: { chars: pair.map((k) => layout.getChar(k, Layer.Default)), keyCodes: pair },
      })
      if (pair[0] !== 'KeyF') {
        chapter.push({
          type: LessonType.FocusOnNewLetters,
          uniqueId: `${chapterKey}-${LessonType.FocusOnNewLetters}-${pair.join('')}`,
          view: TrainerView.ThreeLines,
          availableOnTrial: chapterKey === Chapter.HomeRow,
          metadata: { chars: pair.map((k) => layout.getChar(k, Layer.Default)), keyCodes: pair },
        })
      }
    }
    chapters[chapterKey] = chapter
  }

  // skipped letters: easy keys
  const additionalsChapter: LessonData[] = []
  const languageConfig = allLangConfig[layout.languageCode]
  const typedChars = keyPairsByRow
    .flat(2)
    .map((k) => layout.getChar(k as TypeableKeyCode, Layer.Default))
    .filter((l) => !!l) as string[]
  const skippedLetters = languageConfig.lowerLetters.split('').filter((l) => !typedChars.includes(l))

  for (const letter of skippedLetters) {
    const presses = layout.getKeysToType(new Char(letter))
    if (presses) {
      additionalsChapter.push({
        type: LessonType.NewLetters,
        uniqueId: `${Chapter.AdditionalLetters}-${LessonType.NewLetters}-${letter}`,
        view: TrainerView.RunningLine,
        availableOnTrial: false,
        metadata: { chars: [letter], keyCodes: [last(presses)!.keyCode] },
      })
      additionalsChapter.push({
        type: LessonType.FocusOnNewLetters,
        uniqueId: `${Chapter.AdditionalLetters}-${LessonType.FocusOnNewLetters}-${letter}`,
        view: TrainerView.ThreeLines,
        availableOnTrial: false,
        metadata: { chars: [letter], keyCodes: [last(presses)!.keyCode] },
      })
    }
  }
  if (additionalsChapter.length) {
    chapters[Chapter.AdditionalLetters] = additionalsChapter
  }

  // per finger
  const perFingerChapter: LessonData[] = []
  const allFingers: Finger[] = [Finger.Little, Finger.Ring, Finger.Middle, Finger.Index]
  for (const finger of allFingers) {
    for (const hand of [Hand.Left, Hand.Right]) {
      perFingerChapter.push({
        type: LessonType.SingleFinger,
        uniqueId: `${Chapter.PerFinger}-${LessonType.SingleFinger}-${hand}-${finger}`,
        view: TrainerView.RunningLine,
        availableOnTrial: false,
        metadata: { finger, hand },
      })
    }
  }
  chapters[Chapter.PerFinger] = perFingerChapter

  // n-grams
  const ngramsChapter: LessonData[] = []
  const NGRAMS_COUNT = 10
  const ngramsDict = getNgramsDict(layout.languageCode, 2, layout)
  for (let i = 0; i < NGRAMS_COUNT; i++) {
    ngramsChapter.push({
      type: LessonType.Ngram,
      uniqueId: `${Chapter.Ngrams}-${LessonType.Ngram}-${ngramsDict[i]}`,
      view: TrainerView.ThreeLines,
      availableOnTrial: false,
      metadata: { ngram: ngramsDict[i] },
    })
  }
  chapters[Chapter.Ngrams] = ngramsChapter

  // freq words
  const freqWordsChapter: LessonData[] = []
  for (let i = 0; i < LOWER_WORDS_LESSONS_COUNT; i++) {
    freqWordsChapter.push({
      type: LessonType.PopularLowerWords,
      uniqueId: `${Chapter.FrequentWords}-${LessonType.PopularLowerWords}-${i}`,
      view: TrainerView.ThreeLines,
      availableOnTrial: false,
      metadata: { index: i },
    })
  }
  chapters[Chapter.FrequentWords] = freqWordsChapter

  // shift
  const shiftChapter: LessonData[] = []
  for (const row of [Row.Home, Row.Top, Row.Bottom]) {
    shiftChapter.push({
      type: LessonType.ShiftLettersRow,
      uniqueId: `${Chapter.Shift}-${LessonType.ShiftLettersRow}-${row}`,
      view: TrainerView.RunningLine,
      availableOnTrial: false,
      metadata: { row },
    })
  }
  shiftChapter.push({
    type: LessonType.ShiftPerFinger,
    uniqueId: `${Chapter.Shift}-${LessonType.ShiftPerFinger}`,
    view: TrainerView.RunningLine,
    availableOnTrial: false,
  })
  for (let i = 0; i < 3; i++) {
    shiftChapter.push({
      type: LessonType.ShiftLettersRandom,
      uniqueId: `${Chapter.Shift}-${LessonType.ShiftLettersRandom}-${i}`,
      view: TrainerView.ThreeLines,
      availableOnTrial: false,
      metadata: { index: i },
    })
  }
  chapters[Chapter.Shift] = shiftChapter

  // punctuation
  const punctuationChapter: LessonData[] = []
  const punctuationConfig = languageConfig.punctuation
  const supportedPunctuation = punctuationConfig.filter((p) => p.char.split('').every((c) => !!layout.getKeysToType(new Char(c))))
  for (const punctuation of supportedPunctuation) {
    punctuationChapter.push({
      type: LessonType.PunctuationMark,
      uniqueId: `${Chapter.Punctuation}-${LessonType.PunctuationMark}-${punctuation.char}-${punctuation.type}`,
      view: TrainerView.ThreeLines,
      availableOnTrial: false,
      metadata: { char: punctuation.char, type: punctuation.type },
    })
  }
  // TODO: rm when all punctuation done
  if (punctuationChapter.length) {
    chapters[Chapter.Punctuation] = punctuationChapter
  }

  // summary
  const summaryChapter: LessonData[] = []
  for (let i = 0; i < 5; i++) {
    summaryChapter.push({
      type: LessonType.AllPunctuation,
      uniqueId: `${Chapter.Summary}-${LessonType.AllPunctuation}-${i}`,
      view: TrainerView.ThreeLines,
      availableOnTrial: false,
      metadata: { index: i, punctuation: supportedPunctuation },
    })
  }
  // TODO: rm when all punctuation done
  if (supportedPunctuation.length) {
    chapters[Chapter.Summary] = summaryChapter
  }

  // numbers
  const numbersChapter: LessonData[] = []
  for (const trainingType of Object.keys(numTrainingItems)) {
    numbersChapter.push({
      type: LessonType.Numbers,
      uniqueId: `${Chapter.Numbers}-${LessonType.Numbers}-${trainingType}`,
      view: TrainerView.ThreeLines,
      availableOnTrial: false,
      metadata: { trainingType },
    })
  }
  chapters[Chapter.Numbers] = numbersChapter

  // problems
  // const skippedChaptersForProblems = [Chapter.AdditionalLetters, Chapter.Summary]
  // for (const [chapter, lessons] of Object.entries(chapters)) {
  //   if (skippedChaptersForProblems.includes(chapter as Chapter)) {
  //     continue
  //   }
  //   lessons.push({
  //     type: LessonType.Problems,
  //     view: TrainerView.ThreeLines,
  //     metadata: { chapter },
  //   })
  // }

  return {
    layout,
    content: chapters,
  }
}
