export const LanguageCodes = {
  en: 'en',
  es: 'es',
  de: 'de',
  ru: 'ru',
  fr: 'fr',
  pt: 'pt',
  it: 'it',
  pl: 'pl',
  uk: 'uk',
  tr: 'tr',
  nl: 'nl',
  sv: 'sv',
  hu: 'hu',
  cs: 'cs',
  da: 'da',
  ro: 'ro',
} as const
export type LanguageCode = keyof typeof LanguageCodes

export enum PunctuationMarkType {
  Bracket = 'Bracket', // (word) "word"
  BracketSpaced = 'BracketSpaced', // " word "
  Comma = 'Comma', // word: word;
  CommaSpaced = 'CommaSpaced', // word :
  End = 'End', // word.  (same as Comma, but indicates sentence end)
  EndSpaced = 'EndSpaced', // word !
  EndBracket = 'EndBracket', // ¿word?
  Hyphen = 'Hyphen', // word-word
  Dash = 'Dash', // word - word
  // Apostrophe = 'Apostrophe', // word's words'
}

export type PunctuationMetadata = {
  char: string
  type: PunctuationMarkType
  // applyFn: (val: string) => string
}

export type LanguageMetadata = {
  code: LanguageCode
  title: string
  emoji: string
  lowerLetters: string
  upperLetters: string
  punctuation: PunctuationMetadata[]
  nonEssentialLetters: string

  dicts: {
    words10k: string[]
    ngrams: { 2: string[]; 3: string[]; 4: string[] }
  }
}
