import type { LessonCoords } from '@/course/course-types'
import type { UserSettings } from '@/stores/userStore'
import * as Sentry from '@sentry/vue'
import type { Vue } from 'node_modules/@sentry/vue/build/types/types'
import type { Router } from 'vue-router'

// init

export const installSentry = (app: Vue, router: Router) => {
  if (!isSentryEnabled()) {
    debugLog('init')
  }

  Sentry.init({
    app,
    dsn: 'https://908ecf39bba6414e8cb9fb40071bdf5a@o926701.ingest.us.sentry.io/5876325',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration(), Sentry.browserProfilingIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Profiling
    profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  })
}

// context

export enum SentryContext {
  main = 'main',
  settings = 'settings',
  layouts = 'layouts',
  lesson = 'lesson',
}

export type SentryContextTypes = {
  [SentryContext.main]: MainSentryContext
  [SentryContext.settings]: UserSettings
  [SentryContext.layouts]: LayoutsSentryContext
  [SentryContext.lesson]: LessonCoords | null
}

export type MainSentryContext = {
  hasFullAccess: boolean
  joinDate: string
  regDate?: string
}

export type LayoutsSentryContext = {
  allLayouts: string[]
  currentLayout?: string
}

export const setSentryContext = <T extends SentryContext>(contextId: T, payload: SentryContextTypes[T]) => {
  if (!isSentryEnabled()) {
    debugLog('setSentryContext:' + contextId, payload)
    return
  }
  Sentry.setContext(contextId, payload)
}

// user identification

export const identifyUserInSentry = (id: string, email: string) => {
  if (!isSentryEnabled()) {
    debugLog('identifyUserInSentry', { id, email })
    return
  }
  Sentry.setUser({ id, email })
}

export const resetUserInSentry = () => {
  if (!isSentryEnabled()) {
    debugLog('resetUserInSentry')
    return
  }
  Sentry.setUser(null)
}

// helpers

const isSentryEnabled = () => import.meta.env.PROD

const debugLog = (fnName: string, payload?: any) => {
  console.log(`[mock::sentry] ${fnName}${payload ? ' ' + JSON.stringify(payload) : ''}`)
}
