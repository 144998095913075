<script setup lang="ts">
import type { LanguageCode } from '@/languages/lang-types'
import { computed } from 'vue'

type Props = {
  languageCode: LanguageCode
  type?: 'inline' | 'separate'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'inline',
})

const imgSrc = computed(() => {
  return new URL(`/src/assets/img/flags/flag-${props.languageCode}.png`, import.meta.url).pathname
})
</script>

<template>
  <span class="flag" :class="[props.type]">
    <span>&#8203;</span>
    <img :src="imgSrc" draggable="false" />
    <span>&#8203;</span>
  </span>
</template>

<style lang="scss" scoped>
.flag {
  white-space: nowrap;
  line-height: 1;

  img {
    height: 1.125em;
    width: 1.125em;
  }

  &.inline {
    img {
      vertical-align: middle;
      position: relative;
      bottom: 0.125em;
    }
  }
}
</style>
