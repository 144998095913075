import { onMounted, onUnmounted } from 'vue'

export default function useCustomBodyClass(className: string) {
  onMounted(() => {
    document.querySelector('body')?.classList.add(className)
  })

  onUnmounted(() => {
    document.querySelector('body')?.classList.remove(className)
  })
}
