<script lang="ts" setup>
import { logout, signInWithGoogle } from '@/plugins/firebase/auth'
import { getNativeLangTitle, switchAppLocale, UserLocale } from '@/plugins/i18n'
import { Theme, useAppStore } from '@/stores/appStore'
import { useUserStore, type FingerMappingId } from '@/stores/userStore'
import { onClickOutside, useElementHover } from '@vueuse/core'
import { debounce, type DebouncedFunc } from 'lodash-es'
import { computed, ref, useTemplateRef, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import ToggleButton from './ToggleButton.vue'
import RenameModal from './RenameModal.vue'
import Button from '@/components/Button.vue'
import { Icon } from '@iconify/vue'

const { locale } = useI18n({ useScope: 'global' })
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const appStore = useAppStore()
const userStore = useUserStore()

type Props = {
  variant: 'mainSettings' | 'keyboardSettings'
}
const props = defineProps<Props>()

// main dropdown

const dropdownRef = useTemplateRef('dropdown')

const isDropdownOpened = defineModel<boolean>({ default: false })

onClickOutside(dropdownRef, () => {
  isDropdownOpened.value = false
})

const menuButtonText = computed(() => {
  if (props.variant === 'keyboardSettings') {
    return t('DropdownMenu.keyboard')
  }
  return userStore.name || t('DropdownMenu.settings')
})

// nested dropdowns

const speedUnitItemRef = useTemplateRef('speedUnit')
const themeItemRef = useTemplateRef('theme')
const languageItemRef = useTemplateRef('language')
const fingerMappingItemRef = useTemplateRef('fingerMapping')
const accountItemRef = useTemplateRef('account')

const speedUnitItemHovered = useElementHover(speedUnitItemRef)
const themeItemHovered = useElementHover(themeItemRef)
const languageItemHovered = useElementHover(languageItemRef)
const fingerMappingItemHovered = useElementHover(fingerMappingItemRef)
const accountItemHovered = useElementHover(accountItemRef)

const showNestedDialog = ref<'speedUnit' | 'theme' | 'language' | 'fingerMapping' | 'account' | null>(null)

const debouncedFn = ref<DebouncedFunc<() => void> | null>(null)

watchEffect(() => {
  debouncedFn.value?.cancel()

  let fn
  if (speedUnitItemHovered.value) {
    fn = () => (showNestedDialog.value = 'speedUnit')
  } else if (themeItemHovered.value) {
    fn = () => (showNestedDialog.value = 'theme')
  } else if (languageItemHovered.value) {
    fn = () => (showNestedDialog.value = 'language')
  } else if (fingerMappingItemHovered.value) {
    fn = () => (showNestedDialog.value = 'fingerMapping')
  } else if (accountItemHovered.value) {
    fn = () => (showNestedDialog.value = 'account')
  } else {
    fn = () => (showNestedDialog.value = null)
  }

  debouncedFn.value = debounce(fn, 50)
  debouncedFn.value()
})

watch(
  isDropdownOpened,
  (to) => {
    if (!to) {
      debouncedFn.value?.cancel()
      showNestedDialog.value = null
    }
  },
  { immediate: true },
)

// actions

// main

const changeSpeedUnit = (unit: 'wpm' | 'cpm') => {
  isDropdownOpened.value = false
  userStore.saveSettings({ speedUnit: unit })
}

const changeTheme = (theme: Theme) => {
  isDropdownOpened.value = false
  appStore.theme = theme
}

const changeLocale = (to: UserLocale) => {
  isDropdownOpened.value = false
  switchAppLocale(to)
  userStore.saveSettings({ locale: to })
}

const confirmLogout = async () => {
  isDropdownOpened.value = false
  const confirmed = confirm(t('DropdownMenu.areYouSureAboutLogout'))
  if (confirmed) {
    router.replace({ name: 'home' })
    const locale = userStore.settings.locale
    await logout()
    router.replace({ name: 'landing', params: { locale } })
    window.scrollTo({ top: 0 })
  }
}

const isRenameModalShown = ref(false)
const showRenameModal = () => {
  isDropdownOpened.value = false
  isRenameModalShown.value = true
}

const auth = () => {
  isDropdownOpened.value = false
  signInWithGoogle()
}

// typing

const toggleHands = () => {
  userStore.saveSettings({ isFingerHintShown: !userStore.settings.isFingerHintShown })
}

const toggleFingerMappingHighlight = () => {
  userStore.saveSettings({ isFingerMappingHighlighted: !userStore.settings.isFingerMappingHighlighted })
}

const selectFingerMapping = (mapping: FingerMappingId) => {
  userStore.saveSettings({
    fingerMapping: mapping,
    isFingerMappingHighlighted: true,
  })
}

const showFeedbackModal = () => {
  isDropdownOpened.value = false
  appStore.showFeedbackModal()
}
</script>

<template>
  <div class="dropdown" ref="dropdown" :class="{ landing: route.name === 'landing', 'keyboard-settings': variant === 'keyboardSettings' }">
    <Button variant="outlined" @click="isDropdownOpened = !isDropdownOpened">
      <span class="text">{{ menuButtonText }}</span>
      <svg class="icon arrow-down" :class="{ up: isDropdownOpened }">
        <use href="#icon-arrow"></use>
      </svg>
    </Button>

    <transition name="dropdown-dialog">
      <ul v-show="isDropdownOpened" class="dialog">
        <!-- MAIN -->
        <template v-if="variant === 'mainSettings'">
          <!-- theme -->
          <li ref="theme" class="dialog-item" :class="{ open: showNestedDialog === 'theme' }">
            <svg class="icon prepend">
              <use href="#icon-theme"></use>
            </svg>
            <div class="text">{{ t('DropdownMenu.theme') }}</div>
            <svg class="icon arrow-right">
              <use href="#icon-arrow"></use>
            </svg>
            <transition name="dropdown-dialog">
              <ul v-show="showNestedDialog === 'theme'" class="dialog nested">
                <li class="dialog-item" :class="{ active: appStore.theme === Theme.Dark }" @click="changeTheme(Theme.Dark)">
                  <svg class="icon prepend check">
                    <use href="#icon-check"></use>
                  </svg>
                  <div>{{ t('DropdownMenu.dark') }}</div>
                </li>
                <li class="dialog-item" :class="{ active: appStore.theme === Theme.Light }" @click="changeTheme(Theme.Light)">
                  <svg class="icon prepend check">
                    <use href="#icon-check"></use>
                  </svg>
                  <div>{{ t('DropdownMenu.light') }}</div>
                </li>
                <li class="dialog-item" :class="{ active: appStore.theme === Theme.Auto }" @click="changeTheme(Theme.Auto)">
                  <svg class="icon prepend check">
                    <use href="#icon-check"></use>
                  </svg>
                  <div>{{ t('DropdownMenu.system') }}</div>
                </li>
              </ul>
            </transition>
          </li>
          <!-- speed unit -->
          <li ref="speedUnit" class="dialog-item" :class="{ open: showNestedDialog === 'speedUnit' }">
            <svg class="icon prepend">
              <use href="#icon-gauge"></use>
            </svg>
            <div class="text">{{ t('speed') }}</div>
            <svg class="icon arrow-right">
              <use href="#icon-arrow"></use>
            </svg>
            <transition name="dropdown-dialog">
              <ul v-show="showNestedDialog === 'speedUnit'" class="dialog nested">
                <li class="dialog-item" :class="{ active: userStore.settings.speedUnit === 'wpm' }" @click="changeSpeedUnit('wpm')">
                  <svg class="icon prepend check">
                    <use href="#icon-check"></use>
                  </svg>
                  <div class="text">
                    <div class="title">{{ t('DropdownMenu.wpmDescription') }}</div>
                    <div class="description">{{ t('wpm') }}</div>
                  </div>
                </li>
                <li class="dialog-item" :class="{ active: userStore.settings.speedUnit === 'cpm' }" @click="changeSpeedUnit('cpm')">
                  <svg class="icon prepend check">
                    <use href="#icon-check"></use>
                  </svg>
                  <div class="text">
                    <div class="title">{{ t('DropdownMenu.cpmDescription') }}</div>
                    <div class="description">{{ t('cpm') }}</div>
                  </div>
                </li>
              </ul>
            </transition>
          </li>
          <!-- language -->
          <li ref="language" class="dialog-item" :class="{ open: showNestedDialog === 'language' }">
            <svg class="icon prepend">
              <use href="#icon-language"></use>
            </svg>
            <div class="text">{{ t('language') }}</div>
            <svg class="icon arrow-right">
              <use href="#icon-arrow"></use>
            </svg>

            <transition name="dropdown-dialog">
              <ul v-show="showNestedDialog === 'language'" class="dialog nested">
                <li
                  class="dialog-item"
                  v-for="lc in Object.values(UserLocale)"
                  :key="lc"
                  :class="{ active: locale === lc }"
                  @click="changeLocale(lc)"
                >
                  <svg class="icon prepend check">
                    <use href="#icon-check"></use>
                  </svg>
                  <div>{{ getNativeLangTitle(lc) }}</div>
                </li>
              </ul>
            </transition>
          </li>
          <!-- account -->
          <li v-if="userStore.authData" ref="account" class="dialog-item" :class="{ open: showNestedDialog === 'account' }">
            <svg class="icon prepend">
              <use href="#icon-account"></use>
            </svg>
            <div class="text">{{ t('DropdownMenu.account') }}</div>
            <svg class="icon arrow-right">
              <use href="#icon-arrow"></use>
            </svg>
            <transition name="dropdown-dialog">
              <ul v-show="showNestedDialog === 'account'" class="dialog nested">
                <li class="dialog-item title">{{ userStore.authData.email }}</li>
                <li class="dialog-item" @click="showRenameModal">
                  <svg class="icon prepend">
                    <use href="#icon-edit"></use>
                  </svg>
                  <div class="text">
                    <div class="title">{{ t('editName') }}</div>
                    <div class="description">{{ userStore.name }}</div>
                  </div>
                </li>
                <li class="dialog-item danger" @click="confirmLogout()">
                  <svg class="icon prepend">
                    <use href="#icon-exit"></use>
                  </svg>
                  <div class="text">{{ t('DropdownMenu.logout') }}</div>
                </li>
              </ul>
            </transition>
          </li>
          <template v-else>
            <li class="dialog-item" @click="auth">
              <div class="icon prepend">
                <Icon icon="bi:person-lock" />
              </div>
              <div class="text">{{ $t('login') }}</div>
            </li>
            <li class="dialog-item" @click="auth">
              <div class="icon prepend">
                <Icon icon="bi:person-fill-add" />
              </div>
              <div class="text">{{ $t('signUp') }}</div>
            </li>
          </template>
          <!-- feedback -->
          <li v-if="!appStore.showSeparateFeedbackBtn" class="dialog-item" @click="showFeedbackModal">
            <div class="icon prepend">
              <Icon class="chat" icon="bi:chat-dots-fill" />
            </div>
            <div class="text">{{ $t('DropdownMenu.userFeedbackBtnText') }}</div>
          </li>
        </template>

        <!-- TYPING -->
        <template v-else>
          <!-- hands -->
          <li class="dialog-item" @click="toggleHands">
            <svg class="icon prepend">
              <use href="#icon-hand"></use>
            </svg>
            <div class="text">{{ t('DropdownMenu.showHands') }}</div>
            <ToggleButton @click.prevent="" class="toggle" :checked="userStore.settings.isFingerHintShown" />
          </li>
          <!-- finger mapping highlight -->
          <li class="dialog-item" @click="toggleFingerMappingHighlight">
            <svg class="icon prepend">
              <use href="#icon-mapping-highlight"></use>
            </svg>
            <div class="text">{{ t('DropdownMenu.highlightFingerZones') }}</div>
            <ToggleButton @click.prevent="" class="toggle" :checked="userStore.settings.isFingerMappingHighlighted" />
          </li>
          <!-- finger mapping -->
          <li ref="fingerMapping" class="dialog-item" :class="{ open: showNestedDialog === 'fingerMapping' }">
            <svg class="icon prepend">
              <use href="#icon-finger-mapping"></use>
            </svg>
            <div class="text">{{ t('DropdownMenu.fingerMappingItem') }}</div>
            <svg class="icon arrow-right">
              <use href="#icon-arrow"></use>
            </svg>
            <transition name="dropdown-dialog">
              <ul v-show="showNestedDialog === 'fingerMapping'" class="dialog nested">
                <li class="dialog-item" :class="{ active: userStore.settings.fingerMapping === 'logical' }" @click="selectFingerMapping('logical')">
                  <svg class="icon prepend check">
                    <use href="#icon-check"></use>
                  </svg>
                  <div class="text">
                    <div class="title">{{ t('DropdownMenu.FingerMapping.Standard.title') }}</div>
                    <div class="description wide">{{ t('DropdownMenu.FingerMapping.Standard.description') }}</div>
                  </div>
                </li>
                <li
                  class="dialog-item"
                  :class="{ active: userStore.settings.fingerMapping === 'optimized' }"
                  @click="selectFingerMapping('optimized')"
                >
                  <svg class="icon prepend check">
                    <use href="#icon-check"></use>
                  </svg>
                  <div class="text">
                    <div class="title">{{ t('DropdownMenu.FingerMapping.Optimized.title') }}</div>
                    <div class="description wide">{{ t('DropdownMenu.FingerMapping.Optimized.description') }}</div>
                  </div>
                </li>
              </ul>
            </transition>
          </li>
        </template>
      </ul>
    </transition>
  </div>

  <RenameModal v-model="isRenameModalShown" />
</template>

<style lang="scss" scoped>
.dropdown {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  // overwrites for good look inside ShortcutButton elements
  &.keyboard-settings {
    :deep(.btn) {
      border: none;
      color: var(--c-text-secondary);
      height: 33px;
      padding: 5px;
      margin: -5px;
    }
    .dialog {
      right: -5px;
      top: calc(100% + 0.25rem + 5px);
      &.nested {
        left: calc(100% + 0.25rem);
        right: unset;
      }
    }
  }

  .arrow-down {
    margin-left: var(--s-sm);
    position: relative;
    transition: all 0.2s ease;
    color: var(--c-secondary-icon);
    width: 0.875rem;
    height: 0.875rem;
    &.up {
      transform: rotate(180deg);
    }
  }

  .dialog {
    --vertical-spacing: 6px;
    --arrow-right-width: calc(0.75rem + 2 * var(--s-md));
    --sm-icon-size: 0.75rem;

    list-style: none;
    margin: 0;
    padding: var(--vertical-spacing) 0;
    background-color: var(--c-background);
    border: 1px solid var(--c-secondary-border);
    border-radius: var(--br-md);
    position: absolute;
    right: 0;
    top: calc(100% + 0.25rem);
    width: auto;
    white-space: nowrap;
    box-shadow: var(--box-shadow-default);
    font-weight: normal;

    &.nested {
      top: calc(-1px - var(--vertical-spacing));
      right: calc(100% + 0.25rem);

      .dialog-item {
        // equal empty space for left and right sides
        padding-right: calc(var(--s-md) + var(--sm-icon-size) + var(--gap));

        .text {
          margin-right: 0;
        }
      }
    }

    .dialog-item {
      --gap: var(--s-sm);
      position: relative;
      display: flex;
      gap: var(--gap);
      align-items: center;
      padding: var(--vertical-spacing) var(--s-md);
      color: var(--c-text-primary);
      font-size: var(--fz-sm);
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      &.danger {
        color: var(--c-text-danger);
      }

      &:hover,
      &.open {
        background-color: var(--c-secondary-hover);
      }

      .text {
        margin-right: var(--s-md);

        .description {
          // margin-right: calc(-1 * calc(var(--s-md) + var(--sm-icon-size) + var(--gap)));
          font-size: var(--fz-xs);
          color: var(--c-text-secondary);
          font-weight: normal;

          &.wide {
            width: 200px;
            white-space: wrap;
          }
        }
      }

      .toggle {
        margin-left: auto;
      }

      .icon {
        &.prepend {
          width: 1rem;
          height: 1rem;
        }
        :deep(.iconify) {
          width: 18px;
          height: 18px;
          position: relative;
          bottom: 2px;
          &.chat {
            width: 15px;
            height: 15px;
            bottom: 0px;
          }
        }

        &.check {
          width: var(--sm-icon-size);
          height: var(--sm-icon-size);
          visibility: hidden;
        }

        &.arrow-right {
          width: var(--sm-icon-size);
          height: var(--sm-icon-size);
          margin-left: auto;
          color: var(--c-secondary-icon);
          rotate: 270deg;
        }
      }
      &.active .icon.check {
        visibility: visible;
      }

      &.title {
        padding: var(--vertical-spacing) var(--s-md);
        color: var(--c-text-tertiary);
        font-size: var(--fz-xs);
        &:hover {
          background: none;
          cursor: default !important;
        }
      }
    }

    .divider {
      width: 100%;
      height: var(--vertical-spacing);
    }
  }
}

.dropdown-dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dropdown-dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dropdown-dialog-enter-from,
.dropdown-dialog-leave-to {
  transform: translateY(-0.625rem);
  transform: scale(0.8);
  opacity: 0;
}
</style>
