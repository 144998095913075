<script setup lang="ts">
import { isSafari } from '@/helpers/user-agent-utils'
import { useMouseInElement } from '@vueuse/core'
import { computed, useTemplateRef, type StyleValue } from 'vue'

type CardStyle = {
  card: StyleValue
  glow: StyleValue
}

const card = useTemplateRef('card')
const { elementX, elementY, isOutside, elementHeight, elementWidth } = useMouseInElement(card)

const cardStyle = computed<CardStyle>(() => {
  let result: CardStyle = { card: {}, glow: {} }

  if (isSafari()) {
    return result
  }

  if (isOutside.value) {
    return result
  }

  const centerX = elementWidth.value / 2
  const centerY = elementHeight.value / 2
  const rotateX = (elementY.value - centerY) / 30
  const rotateY = (centerX - elementX.value) / 30

  return {
    card: {
      transform: `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    },
    glow: {
      display: 'block',
      transform: 'translate(-50%, -50%)',
      left: `${elementX.value}px`,
      top: `${elementY.value}px`,
    },
  }
})
</script>

<template>
  <div ref="card" class="card" :class="{ hovered: !isOutside }" :style="cardStyle.card">
    <div class="glow" :style="cardStyle.glow"></div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  overflow: hidden;
  position: relative;
  flex: 1;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--c-feature-card);
  backdrop-filter: blur(32px);
  transition: transform 0.15s ease;

  .content {
    position: relative;
    z-index: 10;
  }

  .glow {
    width: 100px;
    height: 100px;
    background: #e4d1ff;
    // background: radial-gradient(73.6% 73.6% at 42.69% 26.76%, v-bind(glowColor) 0%, rgba(95, 199, 82, 0) 100%);
    opacity: 0.5;
    filter: blur(128px);
    position: absolute;
    display: none;
    z-index: 11;
  }
}
</style>
