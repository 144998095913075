import { installHotjar, installMixpanel } from '@/helpers/analytics'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import './plugins/dayjs'
import './plugins/firebase/firebase'
import { i18n } from './plugins/i18n'
import { installSentry } from './plugins/sentry'
import { installTippy } from './plugins/tippy'
import router from './router'
import { getCookieSettingsFromLocalStorage } from './stores/cookieStore'

const app = createApp(App)
installSentry(app, router)
app.use(createPinia())
app.use(i18n)
app.use(router)
installTippy(app)

if (getCookieSettingsFromLocalStorage().isAnalyticsAllowed) {
  installMixpanel()
  installHotjar()
}

app.mount('#app')
