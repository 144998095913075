import { Accuracy, Speed } from '@/types/metric-types'
import type { Dayjs } from 'dayjs'
import { capitalize, sum } from 'lodash-es'

// works both in browser and nodejs
// @ts-ignore
export const ENV = import.meta?.env ? import.meta.env : process.env

export const isBrowserEnv = !!ENV.MODE

// alternative to built-in method but with strict typing
export const toFixed = (num: number, precision: number): number => {
  const multiplier = Math.pow(10, precision)
  return Math.round(num * multiplier) / multiplier
}

export const isNumChar = (char: string): boolean => {
  return '0123456789'.includes(char)
}

export const capitalizeNormalize = (text: string) => capitalize(text).normalize()

export const average = (arr: number[]): number | undefined => {
  if (arr.length === 0) {
    return undefined
  }
  return sum(arr) / arr.length
}

export const maxArrValue = (arr: number[]): number => {
  if (arr.length === 0) {
    return 0
  }
  return Math.max(...arr)
}

export const minArrValue = (arr: number[]): number => {
  if (arr.length === 0) {
    return 0
  }
  return Math.min(...arr)
}

export const sortNumsWithUndefined = (a: number | undefined, b: number | undefined, direction: 'asc' | 'desc' = 'desc') => {
  if (a === undefined) return 1 // move 'undefined' values to the end
  if (b === undefined) return -1 // move 'undefined' values to the end
  return direction === 'asc' ? a - b : b - a
}

export const normalize = (arr: (number | undefined)[], reverse = false, min: number | undefined = undefined): (number | undefined)[] => {
  const numOnlyArr = arr.filter((v) => v !== undefined) as number[]
  const max = maxArrValue(numOnlyArr)
  min = min ?? minArrValue(numOnlyArr)
  const result = arr.map((p) => {
    if (p === undefined) {
      return p
    }
    if (min === max) {
      return reverse ? 0 : 1
    }
    const normalized = (p - min!) / (max - min!)
    return reverse ? 1 - normalized : normalized
  })
  return result
}

const cloudFunctionUrl = (cloudFunction: string): string => ENV.VITE_CLOUD_FUNCTION_URL + cloudFunction

export const getLastArrayElem = (arr?: any[]) => {
  if (!arr || !arr.length) {
    return null
  }
  return arr[arr.length - 1]
}

export const countOccurrences = (arr: any[], value: any) => {
  return arr.reduce((acc, curr) => (curr === value ? acc + 1 : acc), 0)
}

// const keyProxy = (key: string, layout: LayoutProps): string => {
//   if (layout.format === Formats.ANSI && layout.name === layoutsRU.names.RussianApplePC) {
//     // Without Ё
//     switch (key) {
//       case 'ё':
//         return 'е'
//       case 'Ё':
//         return 'е'
//     }
//   }

//   return key;
// }

export { cloudFunctionUrl }

type RandomNumberOptions = {
  digits?: number[] // use only these digits
  len?: number // specify either `len` or `minLen` & `maxLen`
  minLen?: number
  maxLen?: number
}
export const randomNumber = (options?: RandomNumberOptions): string => {
  const digits = options?.digits ?? [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
  const minLen = options?.minLen ?? 1
  const maxLen = options?.maxLen ?? 5

  const numberLength = options?.len ?? randomMinMax(minLen, maxLen)
  let number = ''

  for (let i = 0; i < numberLength; i++) {
    const randomIndex = Math.floor(Math.random() * digits.length)
    const digit = digits[randomIndex]
    number += digit
  }

  return number
}

export const randomMinMax = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const isCharDigit = (char: string) => {
  return '1234567890'.includes(char)
}

export const isObject = (value: any) => {
  return value && typeof value === 'object' && value.constructor === Object
}

// speed, acc

export const calcSpeed = (typingTimeMs: number, validPresses: number): Speed => {
  const totalPressTimeMin = typingTimeMs / 1000 / 60
  if (!typingTimeMs) {
    return new Speed()
  }
  const cpm = validPresses / totalPressTimeMin
  return new Speed(cpm)
}

export const calcAccuracy = (presses: number, typos: number): Accuracy => {
  const total = presses + typos
  if (!total) {
    return new Accuracy()
  }
  const acc = presses / total
  return new Accuracy(acc)
}

export const saveFileAsJson = (content: object, filename: string) => {
  const json = JSON.stringify(content, null, 2)
  const blob = new Blob([json], { type: 'application/json' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${filename}`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}

export const toISODateString = (d: Dayjs) => d.format('YYYY-MM-DD')
export type DateStringKey = string

// export const isSameLayoutConfig = (
//   config1: LayoutConfig | string[],
//   config2: LayoutConfig | string[],
// ) => {
//   const [layoutId1, langCode1] = Array.isArray(config1)
//     ? config1
//     : [config1.layoutId, config1.langCode]
//   const [layoutId2, langCode2] = Array.isArray(config2)
//     ? config2
//     : [config2.layoutId, config2.langCode]
//   return layoutId1 === layoutId2 && langCode1 === langCode2
// }

// export const forEachNumericEnum = <T extends Record<string, unknown>>(enumObj: T, callback: (value: number) => void): void => {
//   let values = Object.values(enumObj)
//   // Filter out the reverse mappings (string keys) if present
//   values = values.filter((value) => typeof value === 'number')
//   values.forEach((value) => callback(value as number))
// }

// export const numEnumValues = <T extends Record<string, any>>(enumObj: T): number[] => {
//   let values = Object.values(enumObj)
//   // Filter out the reverse mappings (string keys) if present
//   values = values.filter((value) => typeof value === 'number')
//   return values
// }

export const fillSpacesWithNbsp = (str: string) => str.replaceAll(' ', '&nbsp;')

export const safeUnwrap = async <T>(promise: Promise<T>): Promise<[Error | null, T | null]> => {
  try {
    const data = await promise
    return [null, data]
  } catch (error: any) {
    return [error, null]
  }
}
