<script setup lang="ts">
import DropdownMenu from '@/components/DropdownMenu.vue'
import { navigateToLanding } from '@/plugins/i18n'
import { useAppStore } from '@/stores/appStore'
import { useTrainingSessionStore } from '@/stores/trainingSessionStore'
import { useUserStore } from '@/stores/userStore'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import Button from './Button.vue'
import DarkLightImage from './DarkLightImage.vue'

type Props = {
  divided?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  divided: false,
})

const route = useRoute()
const router = useRouter()
const appStore = useAppStore()
const userStore = useUserStore()
const trainingSessionStore = useTrainingSessionStore()

const handleLogoClick = () => {
  const goToLanding = route.name === 'home' || !trainingSessionStore.isActive
  if (goToLanding) {
    trainingSessionStore.clear()
    navigateToLanding(userStore.settings.locale)
    window.scrollTo({ top: 0 })
    return
  }
  router.push({ name: 'home' })
}
</script>

<template>
  <nav :class="{ divided }">
    <div class="left-wrapper">
      <slot name="left">
        <div class="logo-wrapper">
          <RouterLink to="/home" @click="handleLogoClick" class="logo">
            <img class="xmast-hat" v-if="appStore.xmasMode" src="/src/assets/img/xmas/hat.png" alt="" />
            <img src="/src/assets/img/logo-keyboard.png" alt="Typing School" />
            <DarkLightImage path="logo-text-beta" ext="svg" alt="Typing School" />
            <div class="beta-tooltip-anchor" v-tippy="{ content: $t('AppHeader.betaTooltip'), placement: 'right', delay: 100 }"></div>
          </RouterLink>
        </div>
        <slot name="after-logo"></slot>
      </slot>
    </div>

    <slot name="middle"> </slot>

    <div class="right-wrapper">
      <slot name="right">
        <div class="action-items">
          <Button
            v-if="appStore.showSeparateFeedbackBtn"
            @click="appStore.showFeedbackModal"
            v-tippy="$t('DropdownMenu.userFeedbackBtnTooltip')"
            size="sm"
            variant="outlined"
            prependIcon="bi:chat-dots-fill"
          >
            {{ $t('DropdownMenu.userFeedbackBtnText') }}
          </Button>
          <DropdownMenu variant="mainSettings" />
        </div>
      </slot>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  height: var(--header-height, 3.75rem);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: var(--c-background);
  border-bottom: 1px solid transparent;

  &.divided {
    border-bottom-color: var(--c-divider);
  }
}

.left-wrapper {
  display: flex;
  flex: 3;

  .view-title {
    font-weight: 500;
    height: var(--header-inner-height);
    font-size: var(--fz-md);
  }
}

.logo-wrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  position: relative;

  .logo {
    text-decoration: none;
    outline: none;
    font-size: 0;
    :deep(img) {
      height: 2rem;
    }
    .xmast-hat {
      position: absolute;
      left: 16px;
      top: -7px;
      transform: rotate(-4.147deg);
      height: 23px;
      width: auto;
    }
  }

  .beta-tooltip-anchor {
    width: 8px;
    height: 18px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.right-wrapper {
  flex: 3;
  display: flex;
  justify-content: flex-end;

  .action-items {
    display: flex;
    align-items: center;
    gap: var(--s-sm);
  }
}
</style>
